import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import Smartlook from "smartlook-client";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Authenticator } from "@aws-amplify/ui-react";
import { LocaleProvider } from "contexts/localeContext";
import { ToastProvider } from "contexts/toastContext";
import { LabelOptionsProvider } from "contexts/labelOptionsContext";
import { AuthContextProvider } from "contexts/authContext";
import CrashPage from "components/CrashPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_APP_INSIGHTS_KEY,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();

Smartlook.init(process.env.REACT_APP_SMART_LOOK_ID);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <LabelOptionsProvider>
        <LocaleProvider>
          <ToastProvider>
            <AuthContextProvider>
              <Sentry.ErrorBoundary fallback={<CrashPage />}>
                <App />
              </Sentry.ErrorBoundary>
            </AuthContextProvider>
          </ToastProvider>
        </LocaleProvider>
      </LabelOptionsProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
