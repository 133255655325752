import { Auth } from "aws-amplify";
import Cookies from 'js-cookie';
import getDomain from "helpers/getDomain";

const getToken = async() => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

const deliveryCookieOptions = {
  expires: parseInt(process.env.REACT_APP_CANARY_DELIVERY_COOKIE_EXPIRATION, 10) || 365,
  domain: getDomain(),
};

const endpointCookieOptions = {
  expires: parseInt(process.env.REACT_APP_API_ENDPOINT_COOKIE_EXPIRATION, 10) || 60,
  domain: getDomain(),
};

const request = async(token, url) => {
  return fetch(`${process.env.REACT_APP_API_BASEURL}/${url}`, {
		method: 'GET',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
		.then(response => response.json())
		.then(response => {
      return response;
    })
		.catch(err => console.error(err));
}

export const getAppUrl = async() => {
  try {
    const token = await getToken();
    const { appUrl, fe_beta_features = false, apiUrl = null } = await request(token, 'auth/postLogin');
    const url = appUrl ? appUrl + '?login' : null;
    return { 
      canary: fe_beta_features,
      url,
      apiUrl,
    };
  } catch (error) {
    console.error('Cognito token retrieval failed', error);
    // allows for additinal handling of improper url whenener its required
    throw error;
  } 
}

export const preForgotPasswordHook = async(email) => {
  const params = { email };
  const url = `${process.env.REACT_APP_API_BASEURL}/auth/preForgotPassword?${(new URLSearchParams(params)).toString()}`;
  return fetch(url, {
		method: 'GET',
	}).catch(err => console.error(err));
}

export const handleDeliveryMethod = (canary) => {
  const cookieName = process.env.REACT_APP_CANARY_DELIVERY_COOKIE_NAME;
  const cookieValue = process.env.REACT_APP_CANARY_DELIVERY_COOKIE_VALUE;
  canary
    ? Cookies.set(cookieName, cookieValue, deliveryCookieOptions)
    : Cookies.remove(cookieName, deliveryCookieOptions);
}

export const handleAPIEndpoint = (value) => {
  const cookieName = process.env.REACT_APP_API_ENDPOINT_COOKIE_NAME;
  value
    ? Cookies.set(cookieName, value, endpointCookieOptions)
    : Cookies.remove(cookieName, endpointCookieOptions);
}
