import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import { Flex, Link, Text, useTheme } from "@aws-amplify/ui-react";

import { useLocaleState } from "contexts/localeContext";

// temporarily not needed
// import Speed from "./images/Speed.svg";
import Trust from "./images/Trust.svg";
import Workflow from "./images/Workflow.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useStyles from "./sheet";

const carouselItems = [
  {
    src: Workflow,
    name: {
      en: "AP AUTOMATION",
      he: "אוטומציה בטיפול בחשבוניות ספקים",
      es: "Automatización en la gestión de facturas de proveedores.",
    },
    title: {
      en: "Accelerate Your AP Workflows by Up to 80%",
      he: "חסוך עד 80% מזמן העבודה על חשבוניות הספקים",
      es: "Ahorre hasta un 80% de tiempo de trabajo en facturas de proveedores",
    },
    subTitle: {
      en: "Automate the collection, processing and archiving of vendor invoices. Seamlessly integrated with your ERP.",
      he: "אוטומציה של תהליכי איסוף, הקלדה וארכוב חשבוניות הספקים דוקה הינה תוסף המתחבר למערכת הנהלת החשבונות שלכם",
      es:
        "Automatización de recopilación, procesamiento y archivo de facturas de proveedores.\n" +
        "DOKKA es un complemento que se conecta a su sistema de contabilidad.",
    },
    linkTitle: "",
    url: "",
  },
  // temporarily not needed
  // {
  //   src: Speed,
  //   name: {
  //     en: "Financial Close Automation",
  //     he: "אוטומציה בתהליכי סגירת תקופה חשבונאית",
  //     es: "Automatización en procesos de cierre de períodos contables.",
  //   },
  //   title: {
  //     en: "Effortlessly Double Your Financial Close Speed",
  //     he: "חתוך בחצי את זמן העבודה על סגירת תקופה חשבונאית",
  //     es: "Reducir a la mitad el tiempo de trabajo al cerrar un período contable",
  //   },
  //   subTitle: {
  //     en: "Shorten your closing cycle, ensure compliance and gain real time visibility into your financial data.",
  //     he: "חסכו זמן והיו מוכנים לביקורת חשבונאית בכל זמן",
  //     es: "Ahorre tiempo y esté preparado para una auditoría contable en cualquier momento",
  //   },
  //   linkTitle: "",
  //   url: "",
  // },
  {
    src: Trust,
    name: {
      en: "",
      he: "",
      es: "",
    },
    title: {
      en: "Trusted By Over 3,500 Companies Worldwide",
      he: "מעל 3,500 חברות מכל העולם פעילות במערכת",
      es: "Más de 3.500 empresas en todo el mundo son activas en el sistema.",
    },
    subTitle: {
      en: "Are you enjoying DOKKA?",
      he: "האם נהנים ממערכת הדוקה?",
      es: "¿Estás disfrutando de DOKKA?",
    },
    linkTitle: {
      en: "Leave a Review on G2",
      he: "השאירו לנו משוב באתר G2",
      es: "Deja una reseña en G2",
    },
    url: "https://www.g2.com/products/dokka/reviews#reviews",
  },
];

const AsideCarousel = () => {
  const { tokens } = useTheme();
  const { rtl, locale } = useLocaleState();
  const classes = useStyles({ tokens });

  const [isAutoPlay, setIsAutoPlay] = useState(true);

  const changeAutoPlay = () => {
    setIsAutoPlay(document.visibilityState === "visible");
  };

  const onClickItem = () => {
    window.open(`https://dokka.com/${rtl ? "he/" : ""}`, "_blank");
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", changeAutoPlay);
    return () => {
      window.removeEventListener("visibilitychange", changeAutoPlay);
    };
  }, []);

  return (
    <Carousel
      autoPlay={isAutoPlay}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      transitionTime={1000}
      interval={4000}
      className={classes.asideCarousel}
      onClickItem={onClickItem}
    >
      {carouselItems.map(({ src, title, subTitle, name, linkTitle, url }) => {
        return (
          <Flex
            key={title[locale] || title.en}
            direction="column"
            height="100%"
            alignItems="center"
          >
            <Text fontSize={tokens.fontSizes.medium} textTransform="uppercase">
              {name[locale] || name.en}
            </Text>
            <Text
              fontSize={tokens.fontSizes.xl}
              fontWeight={tokens.fontWeights.medium}
            >
              {title[locale] || title.en}
            </Text>
            <img src={src} alt={title[locale] || title.en} height={450} />
            <Text className="legend" fontSize={tokens.fontSizes.medium}>
              {subTitle[locale] || title.en}
            </Text>
            {linkTitle && (
              <Link
                isExternal
                href={url}
                color={tokens.colors.white}
                backgroundColor="#FF492C"
                className="amplify-button"
                onClick={(e) => e.stopPropagation()}
                width="max-content"
                border="none"
                boxShadow="none"
              >
                {linkTitle[locale] || linkTitle.en}
              </Link>
            )}
          </Flex>
        );
      })}
    </Carousel>
  );
};

export default AsideCarousel;
