import { useEffect, useMemo } from "react";
import { Amplify, Auth, Hub, I18n } from "aws-amplify";
import {
  Authenticator,
  Grid,
  ThemeProvider,
  useAuthenticator,
  View,
} from "@aws-amplify/ui-react";

import awsExports from "aws-exports";
import authOverrides from "aws-auth-overrides";
import amplifyAuthHandler from "amplify/handlers";
import components from "components/Authenticator/components";
import services from "services";
import AsideCarousel from "components/AsideCarousel";
import Toasts from "components/Toast";

import { getAppUrl } from "amplify/services";
import { useLocaleState } from "contexts/localeContext";
import { useLabelOptions } from "contexts/labelOptionsContext";
import { useAuthContext } from "contexts/authContext";

import useRedirect from "hooks/useRedirect";
import useHealthCheck from "hooks/useHealthCheck";

import { getTheme } from "./theme";
import AsideWL from "./components/AsideWL";

import getDomain from "helpers/getDomain";

import "@aws-amplify/ui-react/styles.css";
import "App.css";

authOverrides.Auth.cookieStorage.domain = getDomain();
Amplify.configure(awsExports);
Auth.configure(authOverrides);
Hub.listen("auth", amplifyAuthHandler);

export default function App() {
  const { route } = useAuthenticator();
  const { rtl } = useLocaleState();
  const { labelOptions } = useLabelOptions();
  const { setIsResetPasswordFlow } = useAuthContext();
  const { initialForm } = useRedirect();

  const isSignInRoute = route === "signIn";

  const theme = useMemo(() => getTheme(labelOptions.brand), [labelOptions]);

  useHealthCheck();

  // reset variable isResetPasswordFlow if the user come back to the signIn route
  useEffect(() => {
    if (isSignInRoute) {
      setIsResetPasswordFlow(false);
    }
  }, [isSignInRoute, setIsResetPasswordFlow]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        try {
          // we dont handle cookies for canary delivery and apiEndpoint here as this event only
          // handles page refresh with active  authsession. Cookies are handled on pure login
          // only, when there is no active auth session
          const { url } = await getAppUrl();
          window.location = url || process.env?.REACT_APP_LOGIN_REDIRECT_URL + "?login";
        } catch (error) {
          // @to-do add sentry notification once sentry is introduced
          console.error(
            "User is already authorized, however we didnt manage to" +
              "identify further url to redirect to. Error details has been loggen before",
          );
        }
      })
      .catch(() => {});
  }, []);

  return (
    <ThemeProvider theme={theme} direction={rtl ? "rtl" : "ltr"}>
      <Grid
        templateColumns={{
          xl: isSignInRoute || labelOptions.isWL ? "650px 1fr" : "0 1fr",
          large: isSignInRoute || labelOptions.isWL ? "1fr 2fr" : "0 1fr",
          base: "0 1fr",
        }}
        minHeight="100vh"
        width="100%"
        className="girdLayout"
        style={{
          background: labelOptions.isWL
            ? "#F7FAFD"
            : "linear-gradient(180deg, #0C71E7 0%, #00D487 100%)",
        }}
      >
        <View
          height={{
            base: "0px",
            large: isSignInRoute || labelOptions.isWL ? "100%" : "0px",
          }}
        >
          {labelOptions.isWL ? <AsideWL /> : <AsideCarousel />}
        </View>
        <Authenticator
          hideSignUp
          components={components}
          initialState={initialForm}
          services={services}
          formFields={{
            signIn: {
              acknowledgement: {
                isRequired: true,
                type: "hiddenField",
              },
            },
            forceNewPassword: {
              password: {
                label: I18n.get("forceNewPassword.newPassword.label"),
                placeholder: I18n.get(
                  "forceNewPassword.newPassword.placeholder",
                ),
              },
            },
            confirmResetPassword: {
              password: {
                label: I18n.get("forceNewPassword.newPassword.label"),
                placeholder: I18n.get(
                  "forceNewPassword.newPassword.placeholder",
                ),
              },
              confirm_password: {
                placeholder: I18n.get("Please confirm your Password"),
              },
            },
          }}
        />
      </Grid>
      <Toasts />
    </ThemeProvider>
  );
}
